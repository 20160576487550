@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes submenu-container-fade-in {
  0% {
    transform: rotateX(-40deg);
  }
  to {
    transform: rotateX(0deg);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

.mobileScroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.subMenu {
  /* margin-top: 10px; */
  background: rgba(0, 0, 0, 0.1);
}

.textRight {
  text-align: right;
}
.textLeft {
  text-align: left;
}
.textCenter {
  text-align: center;
}

.main-menu {
  position: relative;
}

.main-menu ul li {
  display: inline-block;
  margin-left: 35px;

  display: inline-flex;
  align-items: center;
  flex-direction: row;
}
.main-menu ul li svg {
  color: #fff;
  opacity: 0.7;
}

.main-menu ul li:hover > a,
.mobileMenu a {
  color: #fff;
  opacity: 1;
}

.mobileMenu svg {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  opacity: 0.7;
  color: #fff;
}

.mobileMenu a.active,
.main-menu ul li > a.active {
  /* color: #fff; */
  color: #114550;
  opacity: 1;
}
.main-menu ul li.menu-item-has-children {
  /* margin-right: 17px; */
}
/* .main-menu ul li.menu-item-has-children::before {
  content: "\f078";
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 12px;
} */

.main-menu ul li.menu-item-has-children:hover::after {
  content: "";
  position: absolute;
  top: 100%;
  z-index: 1;
  width: 0;
  height: 0;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid #fff;
  transform: translate(20px, 2px);
}

.main-menu ul li:first-child {
  margin-left: 0;
}

.mobileMenu span,
.mobileMenu a {
  color: #fff;
  opacity: 0.7;
  font-weight: 600;
}

.main-menu ul li a {
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
  display: block;
  padding: 55px 0 25px 0;
  opacity: 0.7;
  cursor: pointer;
  /* text-transform: uppercase; */
}

.sticky-nav-active .main-menu ul li a {
  color: #272b38;
}

.main-menu ul li .submenuWrap {
  position: absolute;
  top: 100%;
  min-width: 260px;
  left: 0;
  right: 0;
  margin: 0;
  border: 0;
  opacity: 0;
  padding-top: 12px;
  visibility: hidden;
  /* border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; */

  /* transition: all 0.3s ease-in; */
  /* animation: submenu-container-fade-in 0.1s ease-in; */
  text-align: left;

  z-index: 9;
}

.main-menu ul li .submenuWrap .submenu {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 16px 0px rgb(60 70 79 / 20%);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow: hidden;
}

.main-menu ul li .submenu li {
  margin: 0;
  padding: 0;
  display: block;
  /* width: 250px; */
}

.main-menu ul li .submenu li:not(:last-child) {
  border-right: 1px solid #f3f5f9;
}

.main-menu ul li .submenu li a {
  padding: 24px;
  opacity: 1;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.main-menu ul li .submenu li a:hover {
  background-image: linear-gradient(-45deg, #35c2d8 0%, #0786a3 100%);
  color: #fff;
}

.main-menu ul li .submenu li a .left {
  position: relative;
}
.main-menu ul li .submenu li a .left::before {
  content: "";
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  top: 0;
  left: 0;
  background-color: #f3f5f9;
}

.main-menu ul li .submenu li a .left img {
  height: 50px;
  width: 50px;
  object-fit: contain;
  padding: 10px;
  position: relative;
}

.main-menu ul li .submenu li a .right {
  margin-top: 5px;
  min-width: 230px;
}

.main-menu ul li .submenu li a .right span {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 700;
  color: #272b38;
}

.main-menu ul li .submenu li a:hover .right span {
  color: #fff;
}

.main-menu ul li .submenu li a .right p {
  margin-top: 5px;
  color: #53565a;
  font-size: 14px;
  line-height: 1.2;
}

.main-menu ul li .submenu li a:hover .right p {
  color: #fff;
}

/* .main-menu ul li .submenu li a::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  width: 10px;
  background: #35c2d8;
  z-index: 99;
  border-radius: unset;
  opacity: 0;
  transition: 0.5s;
} */

.main-menu ul li:hover > .submenuWrap {
  /* top: 100%; */
  opacity: 1;
  visibility: visible;
  /* animation: appear 0.3s ease-in; */
}

.main-menu ul li:hover i::after {
  /* transition: all 0.3s ease-in; */
  display: block;
  animation: appear 0.3s ease-in;
}

.main-menu ul li .submenu li > a.active,
.main-menu ul li .submenu li:hover > a {
  /* padding-left: 20px; */
  opacity: 1;
  color: #114550;
}

/*
.main-menu ul li .submenu li:hover > a::before {
  opacity: 1;
  left: 0;
} */
.menu-icon a {
  color: #fff;
  font-size: 18px;
  display: inline-block;
}
.menu-icon a:hover {
  color: #09ebaf;
}

.contactButton {
  display: inline-block;
  color: #fff;
  background-color: #de3a31;
  border: 0;
  border-radius: 4px;
  padding: 10px;
  margin: 55px 0 25px;
  transition: 0.1s ease-in;
  font-weight: 900;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  box-shadow: 0rem 1px 12px 0 rgb(15 26 61 / 10%);
  position: relative;
  padding-right: 35px;
}

.contactButton:hover {
  color: #fff;
  opacity: 0.7;
  transition: 0.3s ease-in;
}

.contactButton::after {
  position: absolute;
  content: "";
  background-image: url(https://jwplayer.com/wp-content/uploads/2022/11/arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 16px;
  width: 20px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.sticky-nav-active .contactButton {
  margin: 0;
}
/* 
.dropdown {
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 16px 14px 2px rgba(44, 44, 56, 0.1),
    10px 10px 5px -5px rgba(10, 11, 14, 0.1), 1px 2px 2px rgba(50, 59, 78, 0.2);
  display: none;
  left: 0;
  position: absolute;
  top: 100%;
  transform-origin: 0 0;
}

.dropdown .dropdown-inner {
  display: flex;
}

.dropdown .dropdown-inner > ul {
  position: relative;
  min-width: 200px;
}

.dropdown .dropdown-inner > ul:not(:only-child) {
  padding: 2rem 2rem 1rem;
}

.dropdown .level-3-link {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.main-menu .dropdown li a {
  color: #000;
  list-style: none;
  text-decoration: none;
  opacity: 0.6;
  display: inline-block;
  padding: 0.5rem 0;
  transition: opacity 0.1s ease-in-out;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.main-menu .dropdown--main-list:not(.products) > li:first-of-type a {
  padding-top: 0;
}

.main-menu .dropdown li a:hover {
  opacity: 1;
  color: #000;
}

.main-menu ul li:hover > .dropdown {
  animation: dropdown-container-fade-in 0.1s ease-in;
  display: block;
} */

.sticky-nav-active .main-menu ul li a {
  padding: 25px 0;
}

/* .sticky-nav-active {
  left: 0;
  margin: auto;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  z-index: 9999;
  background: #1a196e;
  background: -webkit-linear-gradient(to right, #1a196e, #4e8dd6);
  background: linear-gradient(to right, #1a196e, #4e8dd6);
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}

sticky-outer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
} */

.firstItem {
  padding-top: 155px;
}

.slider-content p span {
  font-size: 30px;
  color: #09ebaf;
  font-weight: 700;
}

.slider-btn .transparent-btn {
  margin-left: 20px;
}

/* button style */
.btn {
  -moz-user-select: none;
  background: #36c2d9;
  border: medium none;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0;
  padding: 22px 43px;
  text-align: center;
  /* text-transform: capitalize; */
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  z-index: 1;
  font-family: "Poppins", sans-serif;
  box-shadow: inset 000px 0px 0px 0px #0688a5;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
  position: relative;
}
.btn:hover {
  -webkit-box-shadow: inset 00px 100px 0px 0px #0688a5;
  box-shadow: inset 00px 100px 0px 0px #0688a5;
  color: #ffffff;
}
.transparent-btn {
  background: none;
  border: 2px solid #fff;
  color: #fff;
  box-shadow: none;
  padding: 20px 43px;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.transparent-btn:hover {
  background: #fff;
  color: #47495a;
  box-shadow: none;
}
.gradient-btn {
  position: relative;
  box-shadow: none;
  background-image: linear-gradient(
    to right,
    #a722f4 0%,
    #7a5cff 50%,
    #a722f4 100%
  );
  background-image: -webkit-linear-gradient(
    to right,
    #a722f4 0%,
    #7a5cff 50%,
    #a722f4 100%
  );
  background-image: -ms-linear-gradient(
    to right,
    #a722f4 0%,
    #7a5cff 50%,
    #a722f4 100%
  );
  background-size: 200% auto;
  color: #fff;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.gradient-btn:hover {
  box-shadow: none;
  background-position: right center;
}
.btn.red {
  background: #de3a31;
  -webkit-box-shadow: inset 000px 0px 0px 0px #0786a3;
  box-shadow: inset 000px 0px 0px 0px #0786a3;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #fff;
}
.btn.red:hover {
  -webkit-box-shadow: inset 00px 100px 0px 0px #0786a3;
  box-shadow: inset 00px 100px 0px 0px #0786a3;
  color: #ffffff;
}
.btn.purple-btn {
  background: #6747c7;
  -webkit-box-shadow: inset 000px 0px 0px 0px #fc5155;
  box-shadow: inset 000px 0px 0px 0px #fc5155;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
  color: #fff;
}
.btn.purple-btn:hover {
  -webkit-box-shadow: inset 00px 100px 0px 0px #fc5155;
  box-shadow: inset 00px 100px 0px 0px #fc5155;
  color: #ffffff;
}
.btn.green-btn {
  background: #6ace26;
  -webkit-box-shadow: inset 000px 0px 0px 0px #5588d3;
  box-shadow: inset 000px 0px 0px 0px #5588d3;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
  color: #fff;
}
.btn.green-btn:hover {
  -webkit-box-shadow: inset 00px 100px 0px 0px #5588d3;
  box-shadow: inset 00px 100px 0px 0px #5588d3;
  color: #ffffff;
}
.btn.blue-btn {
  background: #5588d3;
  -webkit-box-shadow: inset 000px 0px 0px 0px #6ace26;
  box-shadow: inset 000px 0px 0px 0px #6ace26;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
  color: #fff;
}
.btn.blue-btn:hover {
  -webkit-box-shadow: inset 00px 100px 0px 0px #6ace26;
  box-shadow: inset 00px 100px 0px 0px #6ace26;
  color: #ffffff;
}
.breadcrumb > .active {
  color: #888;
}
.p-relative {
  position: relative;
}
.display-ib {
  display: inline-block;
}

.uploadCV {
  text-align: center;
  background: #f3f5f9;
  padding: 2rem;
  border-radius: 8px;
  margin: 2rem 0 0;
  border: 3px dotted #d3d3d3;
}

.uploadCVFilename {
  font-size: 2rem;
  color: #666;
  margin: 1rem 0;
}
